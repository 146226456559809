import { createSelector } from 'reselect';

import { getSelectedClientId, getUserProfile } from 'modules/auth/selectors';
import { LOAD_STATUS, PERMISSIONS } from 'modules/common/constants';
import { REDUCER_NAME, SOURCE_DETAILS_VIEW, SOURCES_LIST_SORT_ATTRIBUTES_MAP } from '../constants';

// sources list
export const getSourcesListItems = (state) => state[REDUCER_NAME].sourcesList.items;
export const getSourcesListPage = (state) => state[REDUCER_NAME].sourcesList.page;
export const getSourcesListPageSize = (state) => state[REDUCER_NAME].sourcesList.pageSize;
export const getSourcesListTotalCount = (state) => state[REDUCER_NAME].sourcesList.totalCount;
export const getSourcesListLoadStatus = (state) => state[REDUCER_NAME].sourcesList.loadStatus;
export const getSourcesListSortColumn = (state) => state[REDUCER_NAME].sourcesList.sortBy;
export const getSourcesListSortOrder = (state) => state[REDUCER_NAME].sourcesList.sortOrder;
export const getSourcesListSearchText = (state) => state[REDUCER_NAME].sourcesList.searchBy;
export const getSourcesListFilters = (state) => state[REDUCER_NAME].sourcesList.filters;

// source details
export const getSourceDetails = (state) => state[REDUCER_NAME].sourceDetails.data;
export const getAdditionalSourceDetails = (state) => state[REDUCER_NAME].sourceDetails.additionalData;
export const getSourceDetailsLoadStatus = (state) => state[REDUCER_NAME].sourceDetails.loadStatus;
export const getSourceDetailsOperationInProgress = (state) => state[REDUCER_NAME].sourceDetails.sourceOperationInProgress;
export const getSourceDetailsView = (state) => state[REDUCER_NAME].sourceDetails.view;

// source form
export const getSourceFormCollectors = (state) => state[REDUCER_NAME].sourceForm.collectors.list;
export const getSourceFormCollectorsLoadStatus = (state) => state[REDUCER_NAME].sourceForm.collectors.loadStatus;
export const getSourceFormManifests = (state) => state[REDUCER_NAME].sourceForm.manifests.objects;
export const getSourceFormManifestsLoadStatus = (state) => state[REDUCER_NAME].sourceForm.manifests.loadStatus;
export const getSourceFormOperationInProgress = (state) => state[REDUCER_NAME].sourceForm.sourceOperationInProgress;
export const getSourceFormSchema = (state) => state[REDUCER_NAME].sourceForm.schema;
export const getSourceFormSchemaBackup = (state) => state[REDUCER_NAME].sourceForm.schemaBackup;
export const getSourceFormMode = (state) => state[REDUCER_NAME].sourceForm.mode;
export const getSourceFormDeduplicateColumns = (state) => state[REDUCER_NAME].sourceForm.deduplicateColumns;
export const getSourceFormDraftFlag = (state) => state[REDUCER_NAME].sourceForm.isOnlyDraftExisting;
export const getSourceFormDatastreamTypes = (state) => state[REDUCER_NAME].sourceForm.datastreamTypes.list;
export const getSourceFormDatastreamTypesLoadStatus = (state) => state[REDUCER_NAME].sourceForm.datastreamTypes.loadStatus;

export const getSourcesListSearchPayload = createSelector(
    getSourcesListPage,
    getSourcesListPageSize,
    getSourcesListSortColumn,
    getSourcesListSortOrder,
    getSourcesListSearchText,
    getSourcesListFilters,
    (page, pageSize, sortBy, ordering, searchString, { states, ownerIds }) => {
        // source name consists of origin and report name, but backend orders results by these fields separately,
        // so we need to pass array with two objects
        const orderBy = sortBy === 'name' ?
            [
                {
                    attribute: 'ORIGIN',
                    ordering
                },
                {
                    attribute: 'REPORT',
                    ordering
                }
            ] :
            [
                {
                    attribute: SOURCES_LIST_SORT_ATTRIBUTES_MAP[sortBy],
                    ordering
                }
            ];

        return {
            orderBy,
            searchString,
            page,
            pageSize,
            states,
            ownerIds
        };
    }
);

export const getSourcesListLayoutProps = createSelector(
    getUserProfile,
    getSourcesListItems,
    getSourcesListLoadStatus,
    getSourcesListTotalCount,
    (profile, items, status, totalCount) => ({
        items,
        status,
        totalCount,
        canView: profile.owners.some((owner) => owner.permissions.includes(PERMISSIONS.SOURCES.VIEW)),
        canManage: profile.owners.some((owner) => owner.permissions.includes(PERMISSIONS.SOURCES.MANAGE))
    })
);

export const getSourcesListProps = createSelector(
    getSourcesListItems,
    getSourcesListLoadStatus,
    getSourcesListSortOrder,
    getSourcesListSortColumn,
    (items, status, sortOrder, sortBy) => ({
        items,
        status,
        sortOrder,
        sortBy
    })
);

export const getSourceDetailsProps = createSelector(
    getUserProfile,
    getSourceDetailsLoadStatus,
    getSourceDetails,
    getAdditionalSourceDetails,
    getSourceDetailsOperationInProgress,
    getSourceDetailsView,
    ({ owners }, loadStatus, data, additionalData, isOperationInProgress, view) => ({
        owners,
        loadStatus,
        data,
        additionalData,
        isOperationInProgress,
        view
    })
);

export const getSourceFormValues = (state) => {
    const { sourceForm } = state.form;

    return sourceForm?.values || {};
};

export const getSourceFormLayoutProps = createSelector(
    getSourceFormManifestsLoadStatus,
    getSourceDetailsLoadStatus,
    getSourceDetails,
    getSourceFormOperationInProgress,
    getUserProfile,
    (manifestsLoadStatus, dataLoadStatus, data, isOperationInProgress, profile) => ({
        isOperationInProgress,
        isDataLoading: [ dataLoadStatus, manifestsLoadStatus ].includes(LOAD_STATUS.LOADING),
        displayName: (data.origin && data.report) ? `${data.origin}_${data.report}` : '',
        owners: profile.owners.filter((owner) => owner.permissions.includes(PERMISSIONS.SOURCES.MANAGE))
    })
);

export const getSourceFormProps = createSelector(
    getSourceDetails,
    getSourceFormCollectors,
    getSourceFormCollectorsLoadStatus,
    getSourceFormManifests,
    getSourceFormManifestsLoadStatus,
    getSourceFormOperationInProgress,
    (
        data,
        collectors,
        collectorsLoadStatus,
        manifests,
        manifestsLoadStatus,
        isOperationInProgress
    ) => ({
        data,
        collectors,
        collectorsLoadStatus,
        manifests,
        manifestsLoadStatus,
        isOperationInProgress
    })
);

export const getGoLiveSectionProps = createSelector(
    getSourceDetails,
    getSourceFormValues,
    getSourceFormSchema,
    getSourceFormMode,
    (data, formValues, schema, mode) => ({
        data,
        formValues,
        schema,
        mode
    })
);

export const getSourceDetailsButtonsProps = createSelector(
    getUserProfile,
    getSelectedClientId,
    getSourceDetailsView,
    getSourceDetails,
    getAdditionalSourceDetails,
    getSourceDetailsLoadStatus,
    getSourceDetailsOperationInProgress,
    (
        { clients, owners },
        selectedClientId,
        view,
        { state: mainSourceVersionState, sourceVersionId: mainSourceVersionId, sourceState, isUsed, ownerId, sourceId },
        { state: additionalSourceVersionState, sourceVersionId:additionalSourceVersionId },
        loadStatus,
        isOperationInProgress
    ) => ({
        view,
        loadStatus,
        isOperationInProgress,
        isUsed,
        ownerId,
        sourceId,
        sourceState,
        mainSourceVersionState,
        additionalSourceVersionState,
        sourceVersionId: view === SOURCE_DETAILS_VIEW.MAIN ? mainSourceVersionId : additionalSourceVersionId,
        canManageAnyOwner: owners.some(({ permissions }) => permissions.includes(PERMISSIONS.SOURCES.MANAGE)),
        canManageCurrentClient: clients.find(({ id }) => id === selectedClientId)?.permissions.includes(PERMISSIONS.FEEDS.MANAGE)
    })
);
