import React, { useCallback, useState, FunctionComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars-2';

import service from 'modules/service';
import { EVENT_CATEGORY, KEY_CODE } from 'modules/common/constants';

import styleConstants from 'assets/styles/theme/exports.module.scss';

import local from './local.module.scss';

export interface SelectInputItem {
    id: string | number;
    name: string | number;
    disabled?: boolean;
    [key: string]: any;
}

export interface GroupedInputItem {
    label: string;
    options: SelectInputItem[];
}

interface SelectInputProps {
    inputProperties: any;
    items: SelectInputItem[] | GroupedInputItem[];
    placeholder?: string | JSX.Element;
    disabled?: boolean;
    multiple?: boolean;
    searchable?: boolean;
    inputId?: string;
    styles?: object;
    limit?: number;
    error?: boolean;
    className?: string;
    optionClassName?: string;
    width?: number | string;
    isLoading?: boolean;
    isClearable?: boolean;
    trackTiming?: boolean;
    trackingName?: string;
    formatOptionLabel?: Function;
    menuButton?: JSX.Element;
}

const customStyles = {
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999
    }),
    control: (provided, { isDisabled, selectProps }) => ({
        ...provided,
        fontFamily: 'inter400',
        fontSize: 14,
        width: selectProps.width,
        minHeight: 36,
        cursor: isDisabled ? 'default' : 'pointer',
        boxShadow: 'none',
        color: styleConstants.textColor,
        borderColor: selectProps.error ?
            styleConstants.errorTextColor :
            selectProps.isSearchable && selectProps.menuIsOpen ? styleConstants.highlightColor : styleConstants.muted,
        backgroundColor: selectProps.error ?
            styleConstants.errorBackgroundColor :
            isDisabled ? styleConstants.disabledInputColor : '#fff',
        '&:hover': {
            color: styleConstants.textColor,
            borderColor: selectProps.error ?
                styleConstants.errorTextColor :
                selectProps.isSearchable && selectProps.menuIsOpen ? styleConstants.highlightColor : styleConstants.muted,
            backgroundColor: selectProps.error ? styleConstants.errorBackgroundColor : styleConstants.highlightColorLight
        }
    }),
    valueContainer: (provided, { isMulti }) => ({
        ...provided,
        padding: '4px 0 3px 11px',
        [isMulti ? 'minHeight' : 'height']: 34,
        // hide input in case when select is not searchable
        // if set display to 'none', select menu doesn't open at all
        'input[readonly]': {
            height: 0
        }
    }),
    singleValue: (provided, { selectProps }) => ({
        ...provided,
        color: styleConstants.textColor,
        opacity: selectProps.isSearchable && selectProps.menuIsOpen ? 0.5 : 1
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: 4,
        color: styleConstants.textColor,
        backgroundColor: styleConstants.neutral200
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: 14,
        color: styleConstants.textColor
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        '&:hover': {
            backgroundColor: styleConstants.errorBackgroundColor,
            color: styleConstants.errorTextColor,
            cursor: 'pointer'
        }
    }),
    placeholder: (provided, { selectProps }) => ({
        ...provided,
        opacity: selectProps.isSearchable && selectProps.menuIsOpen ? 0.5 : 1,
        color: styleConstants.textColor,
        whiteSpace: 'nowrap'
    }),
    indicatorsContainer: () => ({
        width: 32
    }),
    menu: ({ width, ...provided }) => ({
        ...provided,
        minWidth: '100%',
        maxWidth: 912,
        marginTop: 5
    }),
    menuList: (provided) => ({
        ...provided,
        borderRadius: 4
    }),
    option: (provided, { isSelected, isDisabled }) => ({
        ...provided,
        fontFamily: 'inter400',
        fontSize: 14,
        height: 33,
        color: styleConstants.textColor,
        backgroundColor: isSelected ? styleConstants.lighter : '#fff',
        opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? 'default' : 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            backgroundColor: isDisabled ? '#fff' : styleConstants.lighter
        }
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: 14,
        color: styleConstants.textColor
    }),
    loadingMessage: (provided) => ({
        ...provided,
        fontSize: 14,
        color: styleConstants.textColor
    })
};

const getOptionValue = ({ id }) => id;
const getOptionLabel = ({ name }) => name;
const filterOption = ({ label }, inputValue) => label.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
const isOptionDisabled = ({ disabled }) => disabled;
const getNoOptionsMessage = () => <FormattedMessage id='common.noOptions' />;
const getLoadingMessage = () => <FormattedMessage id='common.loading' />;

const DropdownIndicator = ({ selectProps }) => (
    <FontAwesomeIcon
        icon={selectProps.menuIsOpen ? faChevronUp : faChevronDown}
        className={classnames(local.arrow, { [local.disabled]: selectProps.isDisabled })}
    />
);

const Option = ({ children, getStyles, innerRef, innerProps, selectProps, ...props }) => (
    <div
        ref={innerRef}
        className={classnames(css(getStyles('option', props)), selectProps.optionClassName)}
        {...innerProps}
    >
        {children}
    </div>
);

const menuListWithButton = (button) => (props) => (
    <components.MenuList {...props}>
        {
            props.children.length ?
                <Fragment>
                    <Scrollbars autoHeight={true} autoHeightMax={235}>
                        {props.children}
                    </Scrollbars>
                    <div className={local.divider} />
                </Fragment> :
                null
        }
        {button}
    </components.MenuList>
);

const timestamp = () => (new Date()).getTime();

const isGroupedInputItem = (item: SelectInputItem | GroupedInputItem): item is GroupedInputItem =>
    item?.options !== undefined;

const SelectInput: FunctionComponent<SelectInputProps> = ({
    inputProperties,
    items,
    placeholder = <FormattedMessage id='common.select' />,
    disabled = false,
    multiple = false,
    searchable = false,
    error= false,
    isLoading = false,
    isClearable = false,
    width = 320,
    limit,
    styles,
    inputId,
    optionClassName,
    className,
    trackTiming = false,
    trackingName,
    formatOptionLabel,
    menuButton
}) => {
    const { value, onChange, onBlur } = inputProperties;

    const onChangeCallback = useCallback((selectValue) => {
        if (multiple) {
            if (limit && selectValue.length > limit) {
                selectValue = selectValue.slice(0, limit);
            }

            onChange(selectValue.map(({ id }) => id));
        } else {
            onChange(selectValue?.id);
        }
    }, [ multiple, limit, onChange ]);

    const onBlurCallback = useCallback(() => {
        onBlur && onBlur(value);
    }, [ value, onBlur ]);

    const onKeyDown = useCallback((event) => {
        if (event.keyCode === KEY_CODE.ENTER) {
            // prevent form submission
            event.preventDefault();
        }
    }, []);

    const [menuOpenTimestamp, setMenuOpenTimestamp] = useState<number | null>(null);

    const onMenuOpen = useCallback(() => {
        if (trackTiming && (trackingName || inputId)) {
            setMenuOpenTimestamp(timestamp());
        }
    }, [ trackTiming, trackingName, inputId, setMenuOpenTimestamp ]);

    const onMenuClose = useCallback(() => {
        if (trackTiming && (menuOpenTimestamp !== null)) {
            const diff = Math.ceil((timestamp() - menuOpenTimestamp) / 1000);
            setMenuOpenTimestamp(null);

            service.analytics.trackEvent(`Drop-down focused (${trackingName || inputId})`, EVENT_CATEGORY.TIMING, '', diff);
        }
    }, [ trackTiming, trackingName, inputId, menuOpenTimestamp, setMenuOpenTimestamp ]);

    // check if first item is grouped or regular to determine if items need to be flattened
    const flattenedItems: SelectInputItem[] = isGroupedInputItem(items[0]) ?
        (items as GroupedInputItem[]).flatMap(({ options }) => options) :
        items as SelectInputItem[];

    const selectedValue = multiple ?
        flattenedItems.filter(({ id }) => value.includes(id)) :
        // react-select doesn't re-render value if `undefined` is passed
        flattenedItems.find(({ id }) => id === value) || null;

    return (
        <Select
            id={inputId}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            filterOption={filterOption}
            isOptionDisabled={isOptionDisabled}
            value={selectedValue}
            options={items}
            onChange={onChangeCallback}
            onBlur={onBlurCallback}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            isDisabled={disabled}
            isMulti={multiple}
            isSearchable={searchable}
            styles={{ ...customStyles, ...styles }}
            closeMenuOnSelect={!multiple}
            tabSelectsValue={false}
            isClearable={isClearable}
            backspaceRemovesValue={isClearable}
            error={error}
            width={width}
            maxMenuHeight={310}
            menuPlacement='auto'
            menuShouldScrollIntoView={false}
            menuPortalTarget={document.body}
            className={className}
            optionClassName={optionClassName}
            components={{
                Option,
                DropdownIndicator,
                MenuList: menuButton ? menuListWithButton(menuButton) : components.MenuList,
                IndicatorSeparator: null,
                ClearIndicator: null,
                LoadingIndicator: null
            }}
            isLoading={isLoading}
            loadingMessage={getLoadingMessage}
            noOptionsMessage={getNoOptionsMessage}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            formatOptionLabel={formatOptionLabel}
            captureMenuScroll={false}
        />
    );
};

export default SelectInput;
