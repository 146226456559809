import React, { Component, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import {
    SCHEMA_MODE,
    SOURCE_DETAILS_VIEW,
    SOURCE_STATE,
    SOURCE_TRANSIENT_STATE,
    TEST_SOURCE_EXPIRATION_PERIOD_DAYS
} from '../../constants';
import { ingestionApiDefinitions } from 'modules/service/types';
import {
    LOAD_STATUS,
    PERMISSIONS,
    AUTOMATION_ID,
    TARGET_TYPE,
    EVENT_TYPE_GROUP,
    SERVICE
} from 'modules/common/constants';
import SkeletonItem from 'modules/common/components/SkeletonItem';
import ErrorPage from 'modules/common/components/ErrorPage';
import SubscriptionsMenu from 'modules/common/components/SubscriptionsMenu';
import Breadcrumbs, { BreadcrumbItemType } from 'modules/common/components/Breadcrumbs';
import IconWithTooltip from 'modules/common/components/IconWithTooltip';
import TextWithTooltip from 'modules/common/components/TextWithTooltip';
import {
    AdverityCollectorSpecificConfiguration,
    LoadStatus,
    Owner,
    RouteComponentProps
} from 'modules/common/interfaces';
import { alert, formatString } from 'modules/common/utils';
import { intl } from 'modules/i18n';
import SourceDetailsButtons from '../SourceDetailsButtons';

import local from './local.module.scss';

interface SourceDetailsLayoutProps {
    owners: Owner[];
    data: ingestionApiDefinitions['SourceVersionDetailDto'];
    additionalData: ingestionApiDefinitions['SourceVersionDetailDto'];
    loadStatus: LoadStatus;
    loadData: Function;
    resetData: Function;
    goToSourcesListPage: Function;
    setView: Function;
    isOperationInProgress: boolean;
    view: typeof SOURCE_DETAILS_VIEW[keyof typeof SOURCE_DETAILS_VIEW];
}

interface RouteMatchParams {
    ownerId: string;
    sourceId: string;
}

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

class SourceDetailsLayout extends Component<SourceDetailsLayoutProps & RouteComponentProps<RouteMatchParams>> {
    private link;

    public componentDidMount() {
        const { loadData, resetData, match: { params: { ownerId, sourceId } } } = this.props;
        const { canView } = this.checkAccess();

        resetData();

        if (canView) {
            loadData(ownerId, sourceId);
        }
    }

    public componentDidUpdate(prevProps) {
        const { match: { params: { ownerId: prevOwnerId, sourceId: prevSourceId } } } = prevProps;
        const { loadData, resetData, match: { params: { ownerId, sourceId } } } = this.props;

        // in case when owner or source ID in page URL is changed by user, component is not re-mounted, so need to check access again
        if ((ownerId !== prevOwnerId) || (sourceId !== prevSourceId)) {
            const { canView } = this.checkAccess();

            resetData();

            if (canView) {
                loadData(ownerId, sourceId);
            }
        }
    }

    public render() {
        const { canView, canUse, canManage } = this.checkAccess();

        if (!canView) {
            return (
                <ErrorPage error='403' />
            );
        }

        const { name: ownerName } = this.getOwner() as Owner;
        const {
            view,
            data,
            additionalData,
            loadStatus,
            isOperationInProgress,
            match: { params: { sourceId } }
        } = this.props;

        const isLoaded = loadStatus === LOAD_STATUS.LOADED;
        const isMainView = view === SOURCE_DETAILS_VIEW.MAIN;
        const { report, origin, sourceState } = isMainView ? data : additionalData;

        return (
            <div>
                {
                    this.renderBreadcrumbs()
                }
                <div className={local.card}>
                    <div className={classnames(local.titleContainer, 'container-row')}>
                        <div className={local.title}>
                            {
                                isLoaded ?
                                    `${origin}_${report}` :
                                    <SkeletonItem width='320px' height='24px' />
                            }
                        </div>
                        <div className={classnames(local.controlsContainer, 'container-row')}>
                            <SubscriptionsMenu
                                referenceEntityId={sourceId}
                                serviceName={SERVICE.INGESTION}
                                eventTypeGroup={EVENT_TYPE_GROUP.SOURCE}
                                disabled={isOperationInProgress || !isLoaded}
                            />
                            {
                                sourceState !== SOURCE_STATE.PROMOTING &&
                                <SourceDetailsButtons canUse={canUse} canManage={canManage} />
                            }
                        </div>
                    </div>
                    <div className={local.ownerName}>
                        {
                            isLoaded ?
                                ownerName :
                                <SkeletonItem width='160px' height='20px' />
                        }
                    </div>
                    {
                        isLoaded ?
                            this.renderDetails() :
                            this.renderSkeletonRows()
                    }
                </div>
            </div>
        );
    }

    private getOwner = () => {
        const { owners, match: { params: { ownerId } } } = this.props;

        return owners.find(({ id }) => id === ownerId);
    }

    private checkAccess = () => {
        const owner = this.getOwner();

        const canView = Boolean(owner && owner.permissions.includes(PERMISSIONS.SOURCES.VIEW));
        const canUse = Boolean(owner && owner.permissions.includes(PERMISSIONS.SOURCES.USE));
        const canManage = Boolean(owner && owner.permissions.includes(PERMISSIONS.SOURCES.MANAGE));

        return { canView, canUse, canManage };
    }

    private renderDetails = () => {
        const { data, additionalData, view } = this.props;

        const {
            ingestionSettings,
            dataCollectorConfigurations,
            updateTimestamp,
            updatedBy,
            schemaMode,
            schema
        } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        if (!ingestionSettings) {
            return null;
        }

        const {
            ingestMode,
            primaryKeyColumns,
            overwriteByColumns,
            dateColumn
        } = ingestionSettings;

        return (
            <Fragment>
                {
                    this.renderSourceStateLabel()
                }
                {
                    this.renderAdditionalVersionLabel()
                }
                {
                    this.renderSourceVersionStateLabel()
                }
                <div className={local.schema}>
                    <FormattedMessage id='sources.schemaAndIngestion' />
                </div>
                {
                    schemaMode === SCHEMA_MODE.SIMPLE && this.renderSchemaTable()
                }
                {
                    schema &&
                    <div className={local.schemaDownload}>
                        <a
                            href='/#'
                            ref={(ref) => { this.link = ref; }}
                            style={{ display: 'none' }}
                        >
                            Download schema
                        </a>
                        <FontAwesomeIcon icon={faFileAlt} />
                        <span onClick={this.downloadSchema}>
                            schema.avsc
                        </span>
                    </div>
                }
                {
                    Boolean(primaryKeyColumns.length) &&
                    <div>
                        <div className={local.secondaryTitle}>
                            <FormattedMessage id='common.primaryKeyColumns' />
                        </div>
                        {
                            primaryKeyColumns.map((column, index) => (
                                <div key={index} className={classnames(local.propertyValue, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    dateColumn &&
                    <div>
                        <div className={local.secondaryTitle}>
                            <FormattedMessage id='common.dateColumn' />
                        </div>
                        <div className={classnames(local.propertyValue, 'ellipsis')}>
                            {dateColumn}
                        </div>
                    </div>
                }
                <div>
                    <div className={local.secondaryTitle}>
                        <FormattedMessage id='common.ingestMode' />
                    </div>
                    <div className={local.propertyValue}>
                        <FormattedMessage id={`common.ingestMode.${ingestMode}`} defaultMessage={ingestMode || '-'} />
                    </div>
                </div>
                {
                    Boolean(overwriteByColumns?.length) &&
                    <div>
                        <div className={local.secondaryTitle}>
                            <FormattedMessage id='common.overwriteByColumns' />
                        </div>
                        {
                            overwriteByColumns.map((column, index) => (
                                <div key={index} className={classnames(local.propertyValue, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
                {this.renderDeduplicateColumnsTable()}
                {this.renderTargetTypeSpecificSettings()}
                {
                    dataCollectorConfigurations &&
                    <Fragment>
                        <div className={local.collectors}>
                            <FormattedMessage id='common.collectors' />
                        </div>
                        <div className={local.collectorsContainer}>
                            {
                                dataCollectorConfigurations.map(({ collectorId, collectorName, configurationContext, collectorSpecificConfiguration }, idx1) => {
                                    let collectorSpecificConfigurationElement: ReactNode = null;

                                    if (collectorId === REACT_APP_ADVERITY_COLLECTOR_ID && collectorSpecificConfiguration) {
                                        const {
                                            datastreamTypeName,
                                            datastreamConfigurationTemplate
                                        } = collectorSpecificConfiguration as AdverityCollectorSpecificConfiguration;

                                        collectorSpecificConfigurationElement = (
                                            <Fragment>
                                                {
                                                    datastreamTypeName &&
                                                    <div
                                                        className={classnames(local.propertyValue, local.configurationDetailsRow)}>
                                                        <FormattedMessage
                                                            id='common.datastreamType' />: {datastreamTypeName}
                                                    </div>
                                                }
                                                {
                                                    datastreamConfigurationTemplate &&
                                                    <Fragment>
                                                        <div
                                                            className={classnames(local.propertyValue, local.configurationDetailsRow)}>
                                                            <FormattedMessage
                                                                id='sources.datastreamConfigurationTemplate' />:
                                                        </div>
                                                        <div
                                                            className={local.jsonValue}>{JSON.stringify(datastreamConfigurationTemplate, null, 4)}</div>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        );
                                    }

                                    return (
                                        <div key={idx1}>
                                            <div className={classnames(local.secondaryTitle, 'ellipsis')}>{collectorName}</div>
                                            {
                                                configurationContext.map(({ key, value, isSecret }, idx2) => (
                                                    <div key={`${idx1}.${idx2}`} className={classnames(local.propertyValue, local.configurationDetailsRow)}>
                                                        <FormattedMessage id={`${collectorId}.${key}`} defaultMessage={key} />
                                                        : {isSecret && value ? '*'.repeat(12) : <FormattedMessage id={`${collectorId}.${key}.option.${value}`} defaultMessage={value || ' '} />}
                                                    </div>
                                                ))
                                            }
                                            {collectorSpecificConfigurationElement}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Fragment>
                }
                <div className={local.divider} />
                <div className={local.lastUpdatedTitle}>
                    <FormattedMessage id='common.lastUpdated' />
                </div>
                <div className={local.lastUpdatedValue}>
                    {`${moment.utc(updateTimestamp).format('DD MMMM YYYY [at] HH:mm UTC')} by ${updatedBy}`}
                </div>
            </Fragment>
        );
    }

    private renderSourceVersionStateLabel = () => {
        const { data, additionalData, view } = this.props;
        const { state, sourceState, reason, goLiveDateTime } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        return sourceState !== SOURCE_STATE.PROMOTING && state !== SOURCE_TRANSIENT_STATE.VALID ?
            <Fragment>
                <div className={classnames(local.label, this.getLabelClassName(state))}>
                    <FormattedMessage id={`sources.transientState.${state}`} />
                </div>
                {
                    state === SOURCE_TRANSIENT_STATE.VALIDATION_FAILED && reason &&
                    <span
                        className={local.labelButton}
                        onClick={() => {
                            alert({
                                title: <FormattedMessage id='sources.validationError' />,
                                content: reason
                            });
                        }}
                    >
                        <FormattedMessage id='sources.seeReason' />
                    </span>
                }
                {
                    state === SOURCE_TRANSIENT_STATE.PENDING && goLiveDateTime &&
                    <span className={local.pendingDate}>
                        {formatString(intl.formatMessage({ id: 'sources.plannedUpgradeDate' }), moment.utc(goLiveDateTime).format('D MMM YYYY'))}
                    </span>
                }
            </Fragment> :
            null;
    }

    private renderSkeletonRows = () => {
        const blocksCount = 2;
        const rowsCount = 3;

        return Array.apply(null, Array(blocksCount)).map((el1, idx1) => (
            <div key={idx1} className={local.skeletonBlock}>
                <div className={local.skeletonItem}>
                    <SkeletonItem width='160px' height='24px' />
                </div>
                {
                    Array.apply(null, Array(rowsCount)).map((el2, idx2) => (
                        <div key={`${idx1}.${idx2}`} className={local.skeletonItem}>
                            <SkeletonItem width='320px' height='16px' />
                        </div>
                    ))
                }
            </div>
        ));
    }

    private downloadSchema = () => {
        const { link, props: { data, additionalData, view } } = this;
        const { schema } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        if (link && schema) {
            const blob = new Blob([ schema ], { type: 'application/json;charset=utf-8;' });

            link.href = URL.createObjectURL(blob);
            link.download = 'schema.avsc';

            return link.click();
        }
    }

    private renderSchemaTable = () => {
        const { data, additionalData, view } = this.props;
        const { schemaFields } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        return (
            schemaFields && schemaFields.length ?
                <div className={local.schemaContainer}>
                    <div className={classnames(local.schemaRow, 'container-row')}>
                        <div className={local.columnName}>
                            <FormattedMessage id='common.columns' />
                        </div>
                        <div className={local.columnType}>
                            <FormattedMessage id='common.type' />
                        </div>
                        <div className={local.columnNullable}>
                            <FormattedMessage id='common.nullable' />
                        </div>
                    </div>
                    {
                        schemaFields.map(({ name, type, nullable }, idx) => (
                            <div key={idx} className={classnames(local.schemaRow, 'container-row')}>
                                <div className={local.columnName}>
                                    <TextWithTooltip>{name}</TextWithTooltip>
                                </div>
                                <div className={local.columnType}>
                                    <FormattedMessage id={`sources.avroType.${type}`} defaultMessage='Unknown' />
                                </div>
                                {
                                    nullable &&
                                    <div className={local.columnNullable}>
                                        <span className={local.label}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div> :
                null
        );
    }

    private getLabelClassName = (state) => {
        let className = '';

        switch (state) {
            case SOURCE_TRANSIENT_STATE.DRAFT:
                className = local.draft;
                break;
            case SOURCE_TRANSIENT_STATE.PENDING:
                className = local.pending;
                break;
            case SOURCE_TRANSIENT_STATE.VALIDATING:
                className = local.validating;
                break;
            case SOURCE_TRANSIENT_STATE.VALIDATION_FAILED:
                className = local.validationFailed;
                break;
            case SOURCE_TRANSIENT_STATE.CANCELLED:
                className = local.cancelled;
                break;
            case SOURCE_TRANSIENT_STATE.SUPERSEDED:
                className = local.superseded;
                break;
        }

        return className;
    }

    private renderAdditionalVersionLabel = () => {
        const { additionalData: { state }, view, setView } = this.props;

        return view === SOURCE_DETAILS_VIEW.MAIN && state ?
            <div className={classnames(local.additionalVersionLabel, this.getLabelClassName(state))}>
                <span className={local.firstMessage}>
                    <FormattedMessage id={`sources.labelMessage1.${state}`} />
                </span>
                <span className={local.secondMessage}>
                    {
                        !([ SOURCE_TRANSIENT_STATE.PENDING, SOURCE_TRANSIENT_STATE.VALIDATING ] as string[]).includes(state) &&
                        <FormattedMessage id={`sources.labelMessage2.${state}`} />
                    }
                </span>
                <span
                    className={local.link}
                    onClick={() => { setView(SOURCE_DETAILS_VIEW.ADDITIONAL); }}
                >
                    <FormattedMessage id={`sources.linkMessage.${state}`} />
                </span>
            </div> :
            null;
    }

    private renderBreadcrumbs = () => {
        const {
            view,
            goToSourcesListPage,
            setView,
            additionalData: { state: additionalState },
            data: { report, origin, state, sourceState }
        } = this.props;

        const displayName = (origin && report) ? `${origin}_${report}` : '';
        const isMainView = view === SOURCE_DETAILS_VIEW.MAIN;

        const mainViewState = ([ SOURCE_STATE.ARCHIVED, SOURCE_STATE.PROMOTING ] as string[]).includes(sourceState!) ?
            <FormattedMessage id={`sources.state.${sourceState}`} /> :
            state && state !== SOURCE_TRANSIENT_STATE.VALID ?
                <FormattedMessage id={`sources.transientState.${state}`} defaultMessage='Unknown' /> : null;

        const breadcrumbsItems: BreadcrumbItemType[] = [
            {
                id: AUTOMATION_ID.ALL_SOURCES_BREADCRUMB,
                label: (<FormattedMessage id='sources.allSources' />),
                onClick: () => { goToSourcesListPage(); }
            },
            {
                label: (
                    <Fragment>
                        {displayName}
                        {
                            mainViewState &&
                            <Fragment>
                                &nbsp;[{mainViewState}]
                            </Fragment>
                        }
                    </Fragment>
                ),
                onClick: () => { setView(SOURCE_DETAILS_VIEW.MAIN); }
            }
        ];

        if (!isMainView) {
            breadcrumbsItems.push(
                {
                    label: (
                        <Fragment>
                            <span className={classnames(local.breadcrumbsAdditionalName, 'ellipsis')}>
                                {displayName}
                            </span>
                            [<FormattedMessage id={`sources.transientState.${additionalState}`} defaultMessage='Unknown' />]
                        </Fragment>
                    )
                }
            );
        }

        return (
            <Breadcrumbs
                items={breadcrumbsItems}
                selectedItemIndex={isMainView ? 1 : 2}
            />
        );
    }

    private renderDeduplicateColumnsTable = () => {
        const { data, additionalData, view } = this.props;
        const { ingestionSettings: { deduplicate, deduplicateColumns } } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        return (
            <Fragment>
                <div className={local.secondaryTitle}>
                    <FormattedMessage id='sources.deduplicateSourceData' />
                    <FontAwesomeIcon icon={deduplicate ? faCheck : faTimes} className={local.deduplicateIcon} />
                </div>
                {
                    deduplicate && deduplicateColumns && deduplicateColumns.length ?
                        <Fragment>
                            <div className={local.deduplicateHint}>
                                <FormattedMessage id='sources.deduplicateSourceDataMessage' />
                            </div>
                            <div className={local.deduplicateColumnsContainer}>
                                <div className={classnames(local.deduplicateColumn, 'container-row')}>
                                    <div className={local.columnName}>
                                        <FormattedMessage id='common.columns' />
                                    </div>
                                    <div className={local.columnOrder}>
                                        <FormattedMessage id='common.order' />
                                    </div>
                                </div>
                                {
                                    deduplicateColumns.map(({ field, order  }, idx) => (
                                        <div key={idx} className={classnames(local.deduplicateColumn, 'container-row')}>
                                            <div className={local.columnName}>{field}</div>
                                            <div className={local.columnType}>{order}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Fragment> :
                        null
                }
            </Fragment>
        );
    }

    private renderSourceStateLabel = () => {
        const { view, data: { sourceState, createTimestamp } } = this.props;

        if (sourceState === SOURCE_STATE.PROMOTING) {
            return (
                <div className={classnames(local.label, local.promoting)}>
                    <FormattedMessage id='sources.state.PROMOTING' />
                </div>
            );
        }

        if (view === SOURCE_DETAILS_VIEW.MAIN && sourceState === SOURCE_STATE.TEST) {
            return (
                <div className={local.testStateLabelContainer}>
                    <span className={local.testStateLabel}>
                        <FormattedMessage id='sources.sourceTestLabelMessage' />
                        <IconWithTooltip className={local.searchTooltip}>
                            {
                                formatString(
                                    intl.formatMessage({ id: 'sources.sourceTestLabelTooltipWithDate' }),
                                    moment.utc(
                                        createTimestamp + (TEST_SOURCE_EXPIRATION_PERIOD_DAYS + 1) * 24 * 3600 * 1000
                                    ).format('MMMM Do YYYY')
                                )
                            }
                        </IconWithTooltip>
                    </span>
                </div>
            );
        }

        return null;
    }

    private renderTargetTypeSpecificSettings = () => {
        const { data, additionalData, view } = this.props;

        const {
            ingestionSettings: {
                targetTypeSpecificSettings: {
                    [TARGET_TYPE.BIGQUERY]: {
                        partitionByDateColumn,
                        clusterColumns
                    },
                    [TARGET_TYPE.SNOWFLAKE]: {
                        clusteringKey
                    }
                }
            }
        } = view === SOURCE_DETAILS_VIEW.MAIN ? data : additionalData;

        return (
            <Fragment>
                <div className={local.secondaryTitle}>
                    <FormattedMessage id='sources.partitionByDateColumn' />
                    <FontAwesomeIcon icon={partitionByDateColumn ? faCheck : faTimes} className={local.deduplicateIcon} />
                </div>
                {
                    Boolean(clusterColumns.length) &&
                    <div>
                        <div className={local.secondaryTitle}>
                            <FormattedMessage id='sources.bqClusterColumns' />
                        </div>
                        {
                            clusterColumns.map((column, index) => (
                                <div key={index} className={classnames(local.propertyValue, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    Boolean(clusteringKey.length) &&
                    <div>
                        <div className={local.secondaryTitle}>
                            <FormattedMessage id='sources.sfClusteringKey' />
                        </div>
                        {
                            clusteringKey.map((column, index) => (
                                <div key={index} className={classnames(local.propertyValue, 'ellipsis')}>
                                    {column}
                                </div>
                            ))
                        }
                    </div>
                }
            </Fragment>
        );
    }
}

export default SourceDetailsLayout;
