import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import PickersDay from '../PickersDay';

import local from './local.module.scss';

type DateTimePickerProps = Partial<MuiDateTimePickerProps<Moment, Moment>> & Pick<MuiDateTimePickerProps<Moment, Moment>, 'value' | 'onChange'>;

const OpenPickerIcon = () => (
    <span className={local.icon}>
        <FontAwesomeIcon icon={faCalendar} />
    </span>
);

const DateTimePicker: FunctionComponent<DateTimePickerProps> = (props) => {
    const intl = useIntl();

    return (
        <MuiDateTimePicker
            inputFormat='DD MMMM yyyy HH:mm'
            renderInput={(textFieldProps) => (
                <TextField
                    {...textFieldProps}
                    inputProps={{ ...textFieldProps.inputProps, placeholder: intl.formatMessage({ id: 'common.selectDateAndTime' }) }}
                />
            )}
            components={{ OpenPickerIcon }}
            disableMaskedInput={true}
            renderDay={
                (date, selectedDates, { key, ...otherPickersDayProps}) =>
                    <PickersDay key={key} {...otherPickersDayProps} />
            }
            InputProps={{
                classes: {
                    root: local.datetimepickerInput,
                    disabled: local.datetimepickerInputDisabled
                }
            }}
            {...props}
        />
    );
}

export default DateTimePicker;
