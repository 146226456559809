import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, InjectedFormProps } from 'redux-form';
import classnames from 'classnames';

import SelectInput from 'modules/common/components/SelectInput';
import { AUTOMATION_ID, LOAD_STATUS, OVERRIDABLE_FIELD_SUFFIX } from 'modules/common/constants';
import DynamicFieldsSection, { DynamicFieldsSectionProps } from 'modules/common/components/DynamicFieldsSection';
import { SOURCE_STATE } from 'modules/sources/constants';
import { ingestionApiDefinitions } from 'modules/service/types';
import SkeletonSectionLoaderIndicator from 'modules/common/components/SkeletonSectionLoaderIndicator';
import { LoadStatus } from 'modules/common/interfaces';
import DatastreamAuthorizationOrIdField from '../DatastreamAuthorizationOrIdField';
import FeedTargets from '../FeedTargets';

import local from '../FeedFormLayout/local.module.scss';

interface ConfigurationSectionProps {
    isOperationInProgress: boolean;
    isEditMode: boolean;
    isPendingVersion: boolean;
    change: InjectedFormProps['change'];
    getSources: Function;
    getCredentials: Function;
    getSourceDetails: Function;
    getCollectorConfigurationManifest: Function;
    resetCollectorConfigurationManifest: Function;
    sources: Array<ingestionApiDefinitions['SourceListResponseDto']>;
    sourcesLoadStatus: LoadStatus;
    sourceDetailsLoadStatus: LoadStatus;
    sourceDetails: ingestionApiDefinitions['SourceVersionDetailDto'];
    collectorConfigurationManifest: ingestionApiDefinitions['ManifestDto'];
    collectorConfigurationManifestLoadStatus: LoadStatus;
    credentials: Array<ingestionApiDefinitions['CredentialSummaryResponseDto']>;
    credentialsLoadStatus: LoadStatus;
    collectorId: string;
    data: ingestionApiDefinitions['FeedVersionDetailResponseDto'];
    locationState?: { sourceId: string };
    sourceId: string;
}

const { REACT_APP_ADVERITY_COLLECTOR_ID } = process.env;

class ConfigurationSection extends Component<ConfigurationSectionProps> {
    public componentDidMount() {
        const { getSources, getCredentials } = this.props;
        getSources();
        getCredentials();
    }

    public componentDidUpdate(prevProps) {
        const {
            isEditMode,
            isPendingVersion,
            data,
            sourcesLoadStatus,
            sources,
            change,
            getSourceDetails,
            sourceDetails,
            sourceDetailsLoadStatus,
            collectorConfigurationManifestLoadStatus,
            getCollectorConfigurationManifest,
            collectorId,
            sourceId,
            locationState
        } = this.props;

        if (isEditMode) {
            const {
                credentialId,
                sourceId: savedSourceId,
                collectorId: savedCollectorId
            } = data;

            if (sourcesLoadStatus === LOAD_STATUS.LOADED && savedSourceId) {
                const source = sources.find(({id}) => id === savedSourceId);

                if (source) {
                    const { ownerId, id, state } = source;

                    change('sourceId', id);
                    change('sourceState', state);

                    if (sourceDetailsLoadStatus === LOAD_STATUS.REQUIRED) {
                        const params: string[] = [ ownerId, id ];

                        if (isPendingVersion) {
                            params.push(data.sourceVersionInWorkflowId!);
                        }

                        getSourceDetails(...params);
                    }

                    if (sourceDetailsLoadStatus === LOAD_STATUS.LOADED && !collectorId) {
                        const dataCollectorConfiguration = sourceDetails.dataCollectorConfigurations.find(({ collectorId: configurationCollectorId }) => configurationCollectorId === savedCollectorId);

                        if (dataCollectorConfiguration) {
                            change('collectorId', `${savedCollectorId}_${dataCollectorConfiguration.id}`);
                            change('sourceVersionCollectorConfigurationId', dataCollectorConfiguration.id);

                            if (collectorConfigurationManifestLoadStatus === LOAD_STATUS.REQUIRED) {
                                getCollectorConfigurationManifest(savedCollectorId);
                            }
                        }
                    }
                }
            }

            if (credentialId !== prevProps.data.credentialId) {
                change('credentialId', credentialId);
            }
        } else if (locationState && !sourceId && sourcesLoadStatus === LOAD_STATUS.LOADED) {
            const source = sources.find(({id}) => id === locationState.sourceId);

            if (source) {
                const { ownerId, state } = source;

                change('sourceId', locationState.sourceId);
                change('sourceState', state);

                getSourceDetails(ownerId, locationState.sourceId);
            }
        }
    }

    public render() {
        const { isEditMode, change } = this.props;

        return (
            <Fragment>
                {this.renderSourceField()}
                {this.renderCollectorField()}
                {this.renderCollectorDetails()}
                {this.renderCredentialsField()}
                <FeedTargets isEditMode={isEditMode} change={change} />
            </Fragment>
        );
    }

    private renderSourceField = () => {
        const { sourcesLoadStatus, sources, isOperationInProgress } = this.props;

        return (
            <Field
                name='sourceId'
                sourcesLoadStatus={sourcesLoadStatus}
                sources={sources}
                isOperationInProgress={isOperationInProgress}
                component={this.sourceFieldComponent}
            />
        );
    }

    private sourceFieldComponent = ({ input, sources, sourcesLoadStatus, isOperationInProgress, meta }) => {
        const { isEditMode, getSourceDetails, change, resetCollectorConfigurationManifest, locationState } = this.props;

        const onChange = (value) => {
            const { ownerId, state } = sources.find(({ id }) => id === value);

            getSourceDetails(ownerId, value);

            change('sourceState', state);
            change('collectorId', undefined);

            resetCollectorConfigurationManifest();
            input.onChange(value);
        };

        const testStateLabel = (
            <span className={local.sourcesListTestLabel}>
                <FormattedMessage id='sources.state.TEST' />
            </span>
        );

        const items = sources
            .map(({ id, report, origin, state }) =>
                ({
                    id,
                    name: `${origin}_${report}`,
                    showTestLabel: state === SOURCE_STATE.TEST
                })
            )
            .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));

        const placeholderMessageId = isEditMode || locationState?.sourceId ? 'common.loading' : 'feeds.selectSource';
        const placeholder = <FormattedMessage id={placeholderMessageId} />;

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.source' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.FEED_FORM_SOURCE}
                    placeholder={placeholder}
                    items={items}
                    inputProperties={{ ...input, onChange }}
                    disabled={isEditMode || isOperationInProgress}
                    searchable={true}
                    isLoading={sourcesLoadStatus !== LOAD_STATUS.LOADED}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Feed source'
                    optionClassName={local.sourceSelectOption}
                    formatOptionLabel={
                        ({ name, showTestLabel }) => (
                            <Fragment>
                                <span className={local.sourceOptionName}>{name}</span>
                                {showTestLabel && testStateLabel}
                            </Fragment>
                        )
                    }
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderCollectorField = () => {
        const { sourceDetailsLoadStatus, sourceDetails, isOperationInProgress } = this.props;

        if (sourceDetailsLoadStatus === LOAD_STATUS.LOADED) {
            const collectors = sourceDetails.dataCollectorConfigurations;

            return (
                <Field
                    name='collectorId'
                    collectors={collectors}
                    isOperationInProgress={isOperationInProgress}
                    component={this.collectorFieldComponent}
                />
            );
        } else if (sourceDetailsLoadStatus === LOAD_STATUS.LOADING) {
            return (
                <div className={local.sourceConfigurationSkeletonRows}>
                    <SkeletonSectionLoaderIndicator blocksCount={1} />
                </div>
            );
        } else {
            return null;
        }
    }

    private collectorFieldComponent = ({ input, collectors, isOperationInProgress, meta }) => {
        const { change, getCollectorConfigurationManifest } = this.props;

        const onChange = (value) => {
            const [collectorId, id] = value.split('_');

            change('sourceVersionCollectorConfigurationId', id);

            // need to reset 'credentialId', datastream-related fields and collector configuration context when user selects another collector
            change(collectorId, undefined);
            change('credentialId', undefined);
            change('adverityAuthorizationId', undefined);
            change('createNewDatastream', false);

            getCollectorConfigurationManifest(collectorId);
            input.onChange(value);
        };

        const items = collectors ?
            collectors
                .map(({ collectorId, collectorName, id }) => ({ id: `${collectorId}_${id}`, name: collectorName }))
                .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1)) :
            [];

        return (
            <div className={local.field}>
                <div className={local.label}>
                    <FormattedMessage id='common.collector' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.FEED_FORM_COLLECTOR}
                    placeholder={<FormattedMessage id='feeds.selectCollector' />}
                    items={items}
                    inputProperties={{ ...input, onChange }}
                    disabled={isOperationInProgress}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Feed source collector'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderCredentialsField = () => {
        const { credentialsLoadStatus, credentials, isOperationInProgress, collectorId } = this.props;

        if (!collectorId) {
            return null;
        }

        return (
            <Field
                name='credentialId'
                credentialsLoadStatus={credentialsLoadStatus}
                credentials={credentials}
                collectorId={collectorId}
                isOperationInProgress={isOperationInProgress}
                component={this.credentialsFieldComponent}
            />
        );
    }

    private credentialsFieldComponent = ({
        input,
        credentials,
        credentialsLoadStatus,
        collectorId,
        isOperationInProgress,
        meta
    }) => {
        const { isEditMode } = this.props;

        const isCredentialsListLoaded = credentialsLoadStatus === LOAD_STATUS.LOADED;
        const items = credentials
            .filter((credential) => credential.collectorId === collectorId)
            .map(({ id, name }) => ({ id, name }))
            .sort(({ name: name1 }, { name: name2 }) => (name1 > name2 ? 1 : -1));

        const placeholderMessageId = isEditMode && !isCredentialsListLoaded ? 'common.loading' : 'feeds.selectCollectorCredentials';
        const placeholder = <FormattedMessage id={placeholderMessageId} />;

        return (
            <div className={classnames(local.field, local.credentialField)}>
                <div className={local.label}>
                    <FormattedMessage id='common.collectorCredentials' />
                </div>
                <SelectInput
                    inputId={AUTOMATION_ID.FEED_FORM_CREDENTIAL}
                    placeholder={placeholder}
                    items={items}
                    inputProperties={input}
                    disabled={isOperationInProgress}
                    searchable={true}
                    isLoading={credentialsLoadStatus !== LOAD_STATUS.LOADED}
                    error={meta.error}
                    trackTiming={true}
                    trackingName='Feed credential'
                />
                {
                    meta.error &&
                    <div className='form-error-message'>
                        <FormattedMessage id={`validationErrors.${meta.error}`} defaultMessage={meta.error} />
                    </div>
                }
            </div>
        );
    }

    private renderCollectorDetails = () => {
        const {
            data: {
                collectorId: savedCollectorId,
                configurationContext
            },
            isEditMode,
            sourceDetails,
            sourceDetailsLoadStatus,
            collectorId,
            collectorConfigurationManifest,
            collectorConfigurationManifestLoadStatus,
            isOperationInProgress,
            change
        } = this.props;

        if (sourceDetailsLoadStatus === LOAD_STATUS.LOADED && collectorConfigurationManifestLoadStatus === LOAD_STATUS.LOADED) {
            const configuration = sourceDetails.dataCollectorConfigurations.find((collectorConfiguration) => collectorConfiguration.collectorId === collectorId);
            const defaultValues = {};

            const useSavedFeedData = isEditMode && collectorId === savedCollectorId;

            configuration?.configurationContext.forEach(({ key, value, overridable }) => {
                defaultValues[key] = useSavedFeedData ?
                    configurationContext.find((field) => field.key === key)?.value :
                    value;
                defaultValues[`${key}-${OVERRIDABLE_FIELD_SUFFIX}`] = overridable;
            });

            if (useSavedFeedData) {
                // process configuration values, which may be in present in the saved feed configuration, but absent in
                // the source collector configuration
                configurationContext
                    .filter(({ key }) => !defaultValues.hasOwnProperty(key))
                    .forEach(({ key, value }) => {
                        defaultValues[key] = value;
                        defaultValues[`${key}-${OVERRIDABLE_FIELD_SUFFIX}`] = true;
                    });
            }

            let customFields: DynamicFieldsSectionProps['customFields'] = undefined;
            if (!isEditMode && collectorId === REACT_APP_ADVERITY_COLLECTOR_ID) {
                const datastreamTypeId = configuration?.collectorSpecificConfiguration?.datastreamTypeId;

                if (datastreamTypeId) {
                    customFields = {
                        'datastream-id': {
                            checkCondition: ({ viewOnly }) => !viewOnly,
                            component: DatastreamAuthorizationOrIdField,
                            props: { datastreamTypeId }
                        }
                    };
                }
            }

            return (
                <DynamicFieldsSection
                    form='feedForm'
                    change={change}
                    config={collectorConfigurationManifest}
                    disabled={isOperationInProgress || (collectorConfigurationManifestLoadStatus !== LOAD_STATUS.LOADED)}
                    prefix={collectorId}
                    editMode={true}
                    defaultValues={defaultValues}
                    customFields={customFields}
                    viewOnlyForNonEditableFields={true}
                />
            );
        } else if (collectorConfigurationManifestLoadStatus === LOAD_STATUS.LOADING) {
            return (<SkeletonSectionLoaderIndicator />);
        } else {
            return null;
        }
    }
}

export default ConfigurationSection;
