import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ExpandMore } from '@mui/icons-material';

import service from 'modules/service';
import { EVENT_TYPE_GROUP, EVENT_TYPES_MAP, SERVICE } from '../../constants';
import { getErrorText, showErrorSnackbar } from '../../utils';
import Checkbox from '../Checkbox';

import local from './local.module.scss';

interface SubscriptionsMenuProps {
    referenceEntityId: string;
    serviceName: typeof SERVICE[keyof typeof SERVICE];
    eventTypeGroup: typeof EVENT_TYPE_GROUP[keyof typeof EVENT_TYPE_GROUP];
    disabled: boolean;
}

const apiEndpointsMap = {
    [SERVICE.INGESTION]: {
        addSubscription: service.api.addIngestionSubscription,
        deleteSubscription: service.api.deleteIngestionSubscription,
        getSubscriptions: service.api.getIngestionSubscriptionsByReferenceEntityId
    }
};

const SubscriptionsMenu: FunctionComponent<SubscriptionsMenuProps> = ({
    referenceEntityId,
    serviceName,
    eventTypeGroup,
    disabled
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subscriptions, setSubscriptions] = useState({});
    const [isApiCallInProgress, setApiCallInProgress] = useState(false);

    const handleClick = useCallback((event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    }, [ setAnchorEl, disabled ]);

    const handleClose = useCallback(() => {
        if (!isApiCallInProgress) {
            setAnchorEl(null);
        }
    }, [ setAnchorEl, isApiCallInProgress ]);

    const api = apiEndpointsMap[serviceName];

    const toggleSubscription = (eventType) => (event: React.BaseSyntheticEvent) => {
        if (!isApiCallInProgress) {
            setApiCallInProgress(true);

            if (event.target.checked) {
                api.addSubscription({ eventType, referenceEntityId })
                    .then(({ subscriptionId }) => {
                        setSubscriptions({
                            ...subscriptions,
                            [eventType]: subscriptionId
                        });
                    })
                    .catch((error) => { showErrorSnackbar(getErrorText(error)); })
                    .finally(() => { setApiCallInProgress(false); });
            } else {
                const payload = {
                    eventType,
                    referenceEntityId,
                    subscriptionId: subscriptions[eventType]
                };

                api.deleteSubscription(payload)
                    .then(() => {
                        delete subscriptions[eventType];
                        setSubscriptions({ ...subscriptions });
                    })
                    .catch((error) => { showErrorSnackbar(getErrorText(error)); })
                    .finally(() => { setApiCallInProgress(false); });
            }
        }
    };

    useEffect(() => {
        setApiCallInProgress(true);
        api.getSubscriptions(referenceEntityId, eventTypeGroup)
            .then((data) => {
                setSubscriptions(data.reduce((acc, { eventType, subscriptionId }) => {
                    acc[eventType] = subscriptionId;

                    return acc;
                }, {}));
            })
            .catch((error) => { showErrorSnackbar(getErrorText(error)); })
            .finally(() => { setApiCallInProgress(false); });
    }, [ referenceEntityId, eventTypeGroup, api ]);

    return (
        <Fragment>
            <div className={classnames(local.menu, { [local.disabled]: disabled })} onClick={handleClick}>
                <FormattedMessage id='common.subscribe' />
                <ExpandMore className={classnames(local.icon, { [local.iconOpen]: Boolean(anchorEl) })} />
            </div>
            <Menu
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ paper: local.paper }}
            >
                {
                    EVENT_TYPES_MAP[eventTypeGroup].map((eventType, idx) => (
                        <MenuItem
                            key={idx}
                            classes={{
                                root: local.item,
                                focusVisible: local.focusedItem
                            }}
                        >
                            <Checkbox
                                disabled={isApiCallInProgress}
                                inputProperties={{
                                    onChange: toggleSubscription(eventType),
                                    value: Boolean(subscriptions[eventType])
                                }}
                                label={<FormattedMessage id={`common.eventType.${eventType}`} defaultMessage={eventType} />}
                            />
                        </MenuItem>
                    ))
                }
            </Menu>
        </Fragment>
    );
};

export default SubscriptionsMenu;
