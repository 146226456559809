import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';

import RefreshIcon from '../SvgIcons/RefreshIcon';

import local from './local.module.scss';

interface FormSubmitButtonProps {
    id?: string;
    form: string;
    disabled: boolean;
    text: ReactNode;
    width?: string | number;
    onClick?: MouseEventHandler;
    isSubmitting?: boolean;
    secondary?: boolean;
}

const FormSubmitButton: FunctionComponent<FormSubmitButtonProps> = ({
    id,
    form,
    text,
    width,
    onClick,
    isSubmitting,
    disabled = false,
    secondary= false
}) => (
    <div className={classnames({ [local.submitting]: isSubmitting, [local.secondary]: secondary }, 'ls-button')}>
        <button
            type='submit'
            id={id}
            disabled={disabled}
            form={form}
            onClick={onClick}
            style={{ width }}
            className={classnames({ 'btn-transparent': secondary })}
        >
            {
                isSubmitting ?
                    <RefreshIcon className={local.submittingIcon} color='#fff' /> :
                    text
            }
        </button>
    </div>
);

export default FormSubmitButton;
