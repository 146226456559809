import { intl } from 'modules/i18n';

import styleConstants from 'assets/styles/theme/exports.module.scss';

export const CLIENT_ROLES = {
    INGESTION_VIEWER: 'INGESTION_VIEWER',
    INGESTION_RUNNER: 'INGESTION_RUNNER',
    INGESTION_MANAGER: 'INGESTION_MANAGER',
    TRANSFORMATION_VIEWER: 'TRANSFORMATION_VIEWER',
    TRANSFORMATION_RUNNER: 'TRANSFORMATION_RUNNER',
    TRANSFORMATION_MANAGER: 'TRANSFORMATION_MANAGER',
    MEMBERSHIPS_VIEWER: 'MEMBERSHIPS_VIEWER',
    MEMBERSHIPS_ADMIN: 'MEMBERSHIPS_ADMIN',
    DATA_CATALOG_VIEWER: 'DATA_CATALOG_VIEWER',
    MANUAL_UPLOAD_VIEWER: 'MANUAL_UPLOAD_VIEWER',
    MANUAL_UPLOAD_MANAGER: 'MANUAL_UPLOAD_MANAGER',
    REPORTING_MANAGER: 'REPORTING_MANAGER'
} as const;

export const OWNER_ROLES = {
    INGESTION_VIEWER: 'INGESTION_VIEWER',
    INGESTION_USER: 'INGESTION_USER',
    INGESTION_MANAGER: 'INGESTION_MANAGER',
    TRANSFORMATION_VIEWER: 'TRANSFORMATION_VIEWER',
    TRANSFORMATION_USER: 'TRANSFORMATION_USER',
    TRANSFORMATION_MANAGER: 'TRANSFORMATION_MANAGER',
    MEMBERSHIPS_VIEWER: 'MEMBERSHIPS_VIEWER',
    MEMBERSHIPS_ADMIN: 'MEMBERSHIPS_ADMIN',
    REPORTING_MANAGER: 'REPORTING_MANAGER'
} as const;

export const PERMISSIONS = {
    SOURCES: {
        VIEW: 'SOURCES.VIEW',
        USE: 'SOURCES.USE',
        MANAGE: 'SOURCES.MANAGE'
    },
    COLLECTOR_CREDENTIALS: {
        VIEW: 'COLLECTOR_CREDENTIALS.VIEW',
        USE: 'COLLECTOR_CREDENTIALS.USE',
        MANAGE: 'COLLECTOR_CREDENTIALS.MANAGE',
        MANAGE_FROM_REPORT: 'COLLECTOR_CREDENTIALS.MANAGE_FROM_REPORT'
    },
    FEEDS: {
        VIEW: 'FEEDS.VIEW',
        RUN: 'FEEDS.RUN',
        MANAGE: 'FEEDS.MANAGE'
    },
    PIPELINES: {
        VIEW: 'PIPELINES.VIEW',
        USE: 'PIPELINES.USE',
        MANAGE: 'PIPELINES.MANAGE'
    },
    INSTANCES: {
        VIEW: 'INSTANCES.VIEW',
        RUN: 'INSTANCES.RUN',
        MANAGE: 'INSTANCES.MANAGE'
    },
    CATALOGUE: {
        VIEW: 'CATALOGUE.VIEW'
    },
    MEMBERSHIPS: {
        VIEW: 'MEMBERSHIPS.VIEW',
        MANAGE: 'MEMBERSHIPS.MANAGE'
    },
    TARGET_CREDENTIALS: {
        VIEW: 'TARGET_CREDENTIALS.VIEW',
        USE: 'TARGET_CREDENTIALS.USE',
        MANAGE: 'TARGET_CREDENTIALS.MANAGE'
    },
    TARGETS: {
        VIEW: 'TARGETS.VIEW',
        USE: 'TARGETS.USE',
        MANAGE: 'TARGETS.MANAGE'
    },
    UPLOADS: {
        VIEW: 'UPLOADS.VIEW',
        UPLOAD: 'UPLOADS.UPLOAD'
    },
    REPORTS: {
        VIEW: 'REPORTS.VIEW',
        MANAGE: 'REPORTS.MANAGE'
    },
    ADVERITY_AUTHORIZATIONS: {
        VIEW: 'ADVERITY_AUTHORIZATIONS.VIEW',
        MANAGE: 'ADVERITY_AUTHORIZATIONS.MANAGE'
    }
};

export const LOAD_STATUS = {
    REQUIRED: 'REQUIRED',
    LOADING: 'LOADING',
    LOADED: 'LOADED'
} as const;

export const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export const OVERRIDABLE_FIELD_SUFFIX = 'OVERRIDABLE';

// css
export const CONTENT_CONTAINER_MAX_WIDTH = parseInt((styleConstants || {}).contentContainerMaxWidth || '1264px', 10);

// for QA purposes: ODP-2658, ODP-2660, ODP-2663
export const AUTOMATION_ID = Object.freeze({
    // MENU
    REPORTS_TAB: 'odp_Tab_Reports',
    INGESTION_TAB: 'odp_Tab_Ingestion',
    TRANSFORMATION_TAB: 'odp_Tab_Transformation',
    FEEDS_TAB: 'odp_Tab_Feeds',
    TARGETS_TAB: 'odp_Tab_Targets',
    SOURCES_TAB: 'odp_Tab_Sources',
    CREDENTIALS_TAB: 'odp_Tab_Credentials',
    INSTANCES_TAB: 'odp_Tab_Instances',
    PIPELINES_TAB: 'odp_Tab_Pipelines',
    CATALOGUE_TAB: 'odp_Tab_Catalogue',
    UPLOADS_TAB: 'odp_Tab_Uploads',
    SIGN_OUT_BTN: 'odp_Sign_Out_Btn',
    USER_ICON: 'odp_User_Icon',
    ADMIN_MENU: 'odp_Admin_Menu',
    ADMIN_MENU_CLIENTS: 'odp_Admin_Menu_Clients',
    ADMIN_MENU_OWNERS: 'odp_Admin_Menu_Owners',
    ADMIN_MENU_MEMBERS: 'odp_Admin_Menu_Members',
    ADMIN_MENU_GROUPS: 'odp_Admin_Menu_Groups',
    // SOURCES
    NEW_SOURCE_BTN: 'new_Source_Btn',
    NEW_SOURCE_BTN_LIVE: 'new_Source_Btn_Live_Option',
    NEW_SOURCE_BTN_TEST: 'new_Source_Btn_Test_Option',
    SOURCES_SEARCH: 'sources_Toolbar_Search_Input',
    SOURCES_FILTER_BTN: 'sources_Filter_Btn',
    SOURCES_TABLE_NAME: 'sources_Tbl_Name',
    SOURCES_TABLE_OWNER: 'sources_Tbl_Owner',
    SOURCES_TABLE_LAST_UPDATED: 'sources_Tbl_Last_Updated',
    ALL_SOURCES_BREADCRUMB: 'all_Sources_Breadcrumb',
    // SOURCE FORM
    SOURCE_FORM_OWNER: 'new_Own_Drdwn',
    SOURCE_FORM_OWNER_OPTION: 'new_Own_opt_Drdwn',
    SOURCE_FORM_ORIGIN: 'new_Orig_Drdown',
    SOURCE_FORM_ORIGIN_OPTION: 'new_Orig_opt_Drdown',
    SOURCE_FORM_REPORT: 'new_Rep_Txtbox',
    SOURCE_FORM_SAI_BASIC_BTN: 'sai_Bas_Btn',
    SOURCE_FORM_SAI_ADVANCED_BTN: 'sai_Adv_Btn',
    SOURCE_FORM_ADD_COLUMN_BTN: 'add_Column_Btn',
    SOURCE_FORM_PRIMARY_KEY: 'new_Pri_Key_Drdwn',
    SOURCE_FORM_PRIMARY_KEY_OPTION: 'new_Pri_Key_opt_Drdwn',
    SOURCE_FORM_DATE_COLUMN: 'new_Date_Col_Drdwn',
    SOURCE_FORM_DATE_COLUMN_OPTION: 'new_Date_Col_opt_Drdwn',
    SOURCE_FORM_CLUSTER_COLUMNS: 'new_Bq_Clust_key_Drdwn',
    SOURCE_FORM_CLUSTER_COLUMNS_OPTION: 'new_Bq_Clust_key_opt_Drdwn',
    SOURCE_FORM_CLUSTERING_KEY: 'new_Sf_Clust_key_Drdwn',
    SOURCE_FORM_CLUSTERING_KEY_OPTION: 'new_Sf_Clust_key_opt_Drdwn',
    SOURCE_FORM_OVERWRITE_BY_COLUMNS: 'new_Overwrite_by_cols_Drdwn',
    SOURCE_FORM_OVERWRITE_BY_COLUMNS_OPTION: 'new_Overwrite_by_cols_opt_Drdwn',
    SOURCE_FORM_INGEST_MODE: 'new_Ingest_Mode_Drdwn',
    SOURCE_FORM_INGEST_MODE_OPTION: 'new_Ingest_Mode_Option',
    SOURCE_FORM_COLLECTORS: 'collectors_Drdwn',
    SOURCE_FORM_COLLECTORS_OPTION: 'collectors_Drdwn_Option',
    SOURCE_FORM_CANCEL_BTN: 'sources_Canc_Btn',
    SOURCE_FORM_SAVE_AS_DRAFT_BTN: 'sources_Save_As_Draft_Btn',
    SOURCE_FORM_SUBMIT_BTN: 'sources_Sub_Btn',
    SOURCE_FORM_GO_LIVE_DATE_ON: 'sources_GL_On',
    SOURCE_FORM_GO_LIVE_DATE_NOW: 'sources_GL_Now',
    SOURCE_FORM_GO_LIVE_DATE_PICKER: 'sources_GL_Btn',
    SOURCE_EDIT_BTN: 'edit_Source_Btn',
    SOURCE_DELETE_DRAFT_BTN: 'source_Delete_Draft_Btn',
    SOURCE_CANCEL_PENDING_UPGRADE_BTN: 'source_Cancel_Pending_Upgrade_Btn',
    SOURCE_FORM_DEDUPLICATE_CHECKBOX: 'sources_Deduplicate_Cbx',
    SOURCE_DELETE_BTN: 'source_Delete_Btn',
    SOURCE_ARCHIVE_BTN: 'source_Archive_Btn',
    SOURCE_CLONE_LIVE_BTN: 'source_Clone_Live_Btn',
    SOURCE_CLONE_TEST_BTN: 'source_Clone_Test_Btn',
    SOURCE_PROMOTE_BTN: 'source_Promote_Btn',
    SOURCE_CREATE_FEED_BTN: 'source_Create_Feed_Btn',
    SOURCE_ADDITIONAL_ACTIONS_MENU_BTN: 'source_Additional_Actions_Menu_Btn',
    // CREDENTIALS COMMON
    CREDENTIALS_SEARCH: 'credential_Search_Input',
    ALL_CREDENTIALS_BREADCRUMB: 'all_Credentials_Breadcrumb',
    // COLLECTOR CREDENTIALS
    NEW_COLLECTOR_CREDENTIAL_BTN: 'new_Credential_Btn',
    COLLECTOR_CREDENTIALS_TABLE_NAME: 'credential_Tbl_Name',
    COLLECTOR_CREDENTIALS_TABLE_COLLECTOR: 'credential_Tbl_Collector',
    COLLECTOR_CREDENTIALS_TABLE_OWNER: 'credential_Tbl_Owner',
    COLLECTOR_CREDENTIALS_TABLE_LAST_UPDATED: 'credential_Tbl_Last_Updated',
    // COLLECTOR CREDENTIAL FORM
    COLLECTOR_CREDENTIAL_FORM_NAME: 'unique_Credential_Name',
    COLLECTOR_CREDENTIAL_FORM_OWNER: 'credential_Owner_Drdwn',
    COLLECTOR_CREDENTIAL_FORM_OWNER_OPTION: 'credential_Owner_opt',
    COLLECTOR_CREDENTIAL_FORM_COLLECTOR: 'credential_Collector_Drdwn',
    COLLECTOR_CREDENTIAL_FORM_COLLECTOR_OPTION: 'credential_Collector_opt',
    COLLECTOR_CREDENTIAL_FORM_CANCEL_BTN: 'credential_Cancel_Btn',
    COLLECTOR_CREDENTIAL_FORM_SAVE_BTN: 'credential_Save_Btn',
    COLLECTOR_CREDENTIAL_EDIT_BTN: 'edit_Credential_Btn',
    // FEEDS
    NEW_FEED_BTN: 'new_Feed_Btn',
    FEEDS_SEARCH: 'feeds_Search_Bar',
    ALL_FEEDS_BREADCRUMB: 'all_Feeds_Breadcrumb',
    FEED_FORM_SOURCE: 'feed_Source_Drdwn',
    FEED_FORM_CREDENTIAL: 'feed_Credential_Drdwn',
    FEED_FORM_COLLECTOR: 'feed_Collector_Drdwn',
    FEED_RUNS_BTN: 'feed_Runs_Btn',
    // FEED RUNS
    FEED_RUNS_SEARCH: 'feed_runs_Search_Bar',
    FEEDS_RERUN_BTN: 'rerun_Feeds_Btn',
    FEED_RUNS_FILTER_BTN: 'filter_Feed_Runs_Btn',
    // FEED DETAILS
    FEED_EDIT_BTN: 'feed_Edit_Btn',
    FEED_UPGRADE_BTN: 'feed_Upgrade_Btn',
    FEED_DELETE_BTN: 'feed_Delete_Btn',
    FEED_RUN_BTN: 'feed_Run_Btn',
    FEED_SCHEDULE_TOGGLE_BTN: 'feed_Schedule_Toggle_Btn',
    // ADMIN / CLIENTS
    NEW_CLIENT_BTN: 'new_Client_Btn',
    CLIENTS_TABLE_NAME: 'client_Tbl_Name',
    CLIENT_FORM_CANCEL_BTN: 'client_Cancel_Btn',
    CLIENT_FORM_SAVE_BTN: 'client_Save_Btn',
    ALL_CLIENTS_BREADCRUMB: 'all_Clients_Breadcrumb',
    CLIENTS_SEARCH: 'clients_Search_Bar',
    CLIENT_MEMBERSHIPS_SEARCH: 'client_memberships_Search_Bar',
    ADD_CLIENT_MEMBER_BTN: 'add_client_member_Btn',
    EDIT_CLIENT_BTN: 'edit_client_Btn',
    DELETE_CLIENT_BTN: 'delete_client_Btn',
    // ADMIN / OWNERS
    NEW_OWNER_BTN: 'new_Owner_Btn',
    OWNERS_TABLE_NAME: 'owner_Tbl_Name',
    OWNER_FORM_CANCEL_BTN: 'owner_Cancel_Btn',
    OWNER_FORM_SAVE_BTN: 'owner_Save_Btn',
    ALL_OWNERS_BREADCRUMB: 'all_Owners_Breadcrumb',
    OWNERS_SEARCH: 'owners_Search_Bar',
    OWNER_MEMBERSHIPS_SEARCH: 'owner_memberships_Search_Bar',
    ADD_OWNER_MEMBER_BTN: 'add_owner_member_Btn',
    DELETE_OWNER_BTN: 'delete_owner_Btn',
    // ADMIN / MEMBERS
    MEMBERS_SEARCH: 'members_Search_Bar',
    NEW_MEMBER_BTN: 'new_Member_Btn',
    ALL_MEMBERS_BREADCRUMB: 'all_Members_Breadcrumb',
    ADD_OWNER_BTN: 'add_owner_Btn',
    ADD_CLIENT_BTN: 'add_client_Btn',
    ADD_GROUP_BTN: 'add_group_Btn',
    MEMBER_OWNER: 'member_Owner_Drdwn',
    MEMBER_CLIENT: 'member_Client_Drdwn',
    // ADMIN / GROUPS
    GROUPS_SEARCH: 'groups_Search_Bar',
    NEW_GROUP_BTN: 'new_Group_Btn',
    GROUP_FORM_CANCEL_BTN: 'group_Cancel_Btn',
    GROUP_FORM_SAVE_BTN: 'group_Save_Btn',
    ALL_GROUPS_BREADCRUMB: 'all_Groups_Breadcrumb',
    ADD_GROUP_MEMBERS_BTN: 'add_group_members_Btn',
    DELETE_GROUP_BTN: 'delete_group_Btn',
    EDIT_GROUP_BTN: 'edit_group_Btn',
    // PIPELINES
    PIPELINES_SEARCH: 'pipelines_Toolbar_Search_Input',
    PIPELINES_TABLE_NAME: 'pipelines_Tbl_Name',
    PIPELINES_TABLE_DAG_ID: 'pipelines_Tbl_Dag_Id',
    PIPELINES_TABLE_OWNER: 'pipelines_Tbl_Owner',
    PIPELINES_TABLE_LAST_UPDATED: 'pipelines_Tbl_Last_Updated',
    ALL_PIPELINES_BREADCRUMB: 'all_Pipelines_Breadcrumb',
    PIPELINE_EDIT_BTN: 'edit_Pipeline_Btn',
    PIPELINE_FORM_CANCEL_BTN: 'pipeline_Cancel_Btn',
    PIPELINE_FORM_SAVE_BTN: 'pipeline_Save_Btn',
    PIPELINE_FORM_NAME: 'unique_Pipeline_Name',
    PIPELINE_FORM_DESCRIPTION: 'pipeline_Description',
    // INSTANCES
    NEW_INSTANCE_BTN: 'new_Instance_Btn',
    INSTANCES_SEARCH: 'instances_Toolbar_Search_Input',
    INSTANCES_TABLE_NAME: 'instances_Tbl_Name',
    INSTANCES_TABLE_LAST_RUN: 'instances_Tbl_Last_Run',
    INSTANCES_TABLE_NEXT_RUN: 'instances_Tbl_Next_Run',
    ALL_INSTANCES_BREADCRUMB: 'all_Instances_Breadcrumb',
    INSTANCE_FORM_NAME: 'unique_Instance_Name',
    INSTANCE_FORM_PURPOSE: 'unique_Instance_Purpose',
    INSTANCE_FORM_DESCRIPTION: 'instance_Description',
    INSTANCE_FORM_PIPELINE: 'instance_Pipeline_Drdwn',
    INSTANCE_FORM_SCHEDULE_HOUR: 'instance_Schedule_Hour_Drdwn',
    INSTANCE_FORM_SCHEDULE_MINUTE: 'instance_Schedule_Minute_Drdwn',
    INSTANCE_FORM_SCHEDULE_DAY: 'instance_Schedule_Day_Drdwn',
    INSTANCE_FORM_SCHEDULE_WEEKDAY: 'instance_Schedule_Weekday_Drdwn',
    INSTANCE_FORM_EXPORT_TARGETS: 'instance_Export_Targets_Drdwn',
    INSTANCE_FORM_SAVE_BTN: 'instance_Save_Btn',
    INSTANCE_FORM_SAVE_AS_DRAFT_BTN: 'instance_Save_As_Draft_Btn',
    INSTANCE_FORM_SAVE_AS_NEW_BTN: 'instance_Save_As_New_Btn',
    INSTANCE_FORM_INGEST_MODE: 'instance_Ingest_Mode_Drdwn',
    INSTANCE_FORM_INGEST_MODE_OPTION: 'instance_Ingest_Mode_Option',
    INSTANCE_FORM_PRIMARY_KEY: 'instance_Pri_Key',
    INSTANCE_FORM_PARTITION: 'instance_Part_Key',
    INSTANCE_FORM_CLUSTER: 'instance_Clust_Key',
    INSTANCE_FORM_OVERWRITE_BY_COLUMNS: 'instance_Overwrite_By_Cols',
    // INSTANCE DETAILS
    INSTANCE_ARCHIVE_BTN: 'instance_Archive_Btn',
    INSTANCE_RESTORE_BTN: 'instance_Restore_Btn',
    INSTANCE_EDIT_BTN: 'instance_Edit_Btn',
    INSTANCE_SCHEDULE_TOGGLE_BTN: 'instance_Schedule_Toggle_Btn',
    INSTANCE_RUN_BTN: 'instance_Run_Btn',
    INSTANCE_RUN_TEST_MODE_BTN: 'instance_Run_Test_Mode_Btn',
    INSTANCE_DELETE_BTN: 'instance_Delete_Btn',
    // DATA CATALOG
    DATA_CATALOG_SEARCH: 'data_Catalog_Search_Input',
    // TARGET CREDENTIALS
    TARGET_CREDENTIAL_FORM_NAME: 'target_Credential_Name',
    TARGET_CREDENTIAL_FORM_OWNER: 'target_Credential_Owner_Drdwn',
    TARGET_CREDENTIAL_FORM_TYPE: 'target_Credential_Type_Drdwn',
    TARGET_CREDENTIAL_FORM_AUTH_METHOD: 'target_Credential_Auth_Method_Drdwn',
    TARGET_CREDENTIAL_FORM_CANCEL_BTN: 'target_Credential_Cancel_Btn',
    TARGET_CREDENTIAL_FORM_SAVE_BTN: 'target_Credential_Save_Btn',
    NEW_TARGET_CREDENTIAL_BTN: 'new_Target_Credential_Btn',
    TARGET_CREDENTIAL_EDIT_BTN: 'edit_Target_Credential_Btn',
    // TARGETS
    ALL_TARGETS_BREADCRUMB: 'all_Targets_Breadcrumb',
    NEW_TARGET_BTN: 'new_Target_Btn',
    TARGET_EDIT_BTN: 'edit_Target_Btn',
    TARGET_DELETE_BTN: 'delete_Target_Btn',
    TARGET_FORM_NAME: 'target_Name',
    TARGET_FORM_OWNER: 'targetl_Owner_Drdwn',
    TARGET_FORM_TYPE: 'target_Type_Drdwn',
    TARGET_FORM_CANCEL_BTN: 'target_Cancel_Btn',
    TARGET_FORM_SAVE_BTN: 'target_Save_Btn',
    // REPORTS
    NEW_REPORT_BTN: 'new_Report_Btn',
    ALL_REPORTS_BREADCRUMB: 'all_Reports_Breadcrumb',
    // ADVERITY AUTHORIZATIONS
    ADVERITY_AUTHORIZATION_FORM_CANCEL_BTN: 'adverity_Authorization_Cancel_Btn',
    ADVERITY_AUTHORIZATION_FORM_SAVE_BTN: 'adverity_Authorization_Save_Btn',
    NEW_ADVERITY_AUTHORIZATION_BTN: 'new_Adverity_Authorization_Btn'
});

export const CRON_HOUR_VALUES = Array.from({ length: 24 }, (el, i) => i.toString());

export const HOUR_SELECT_OPTIONS = CRON_HOUR_VALUES.map((value) => ({ id: value, name: value }));

export const CRON_MINUTE_VALUES = Array.from({ length: 60 }, (el, i) => i.toString());

export const MINUTE_SELECT_OPTIONS = CRON_MINUTE_VALUES.map((value) => ({
    id: value,
    name: value.padStart(2, '0')
}));

export const WEEKDAYS = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ];

export const WEEKDAY_SELECT_OPTIONS = WEEKDAYS.map((day) => ({
    id: day,
    name: intl.formatMessage({ id: `common.weekdays.${day}` })
}));

export const CRON_DAY_OF_MONTH_VALUES = Array.from({ length: 31 }, (el, i) => (i + 1).toString());

export const DAY_OF_MONTH_SELECT_OPTIONS = CRON_DAY_OF_MONTH_VALUES.map((value) => ({ id: value, name: value }));

export const SCHEDULE_FREQUENCY = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    CUSTOM: 'CUSTOM'
} as const;

export const CRON_DEFAULT_VALUE = '*';

export const KEY_CODE = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    SHIFT: 16,
    CTRL: 17,
    ALT: 18,
    ESCAPE: 27,
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
};

export const MANIFEST_FIELD_TYPE = {
    TEXT: 'TEXT',
    PASSWORD: 'PASSWORD',
    CHECKBOX: 'CHECKBOX',
    RADIO: 'RADIO',
    DROPDOWN: 'DROPDOWN',
    MULTI_SELECTION: 'MULTI_SELECTION',
    AP_SQL: 'APSQL',
    INTEGER: 'INTEGER'
};

export const EVENT_CATEGORY = {
    ALL: 'All',
    SOURCES: 'Sources',
    CREDENTIALS: 'Credentials',
    FEEDS: 'Feeds',
    ADMIN: 'Admin',
    TIMING: 'Timing',
    PIPELINES: 'Transformation pipelines',
    INSTANCES: 'Transformation instances',
    FEED_RUNS: 'Feed runs',
    DATA_CATALOG: 'Data catalogue',
    TARGETS: 'Targets',
    UPLOADS: 'Uploads',
    REPORTS: 'Reports'
};

export const USER_TRAIT = {
    USER_TYPE: 'user_type'
};

export const USER_TYPE = {
    INTERNAL: 'Internal',
    EXTERNAL: 'External'
};

export const INGEST_MODE = {
    INSERT_OR_OVERWRITE_BY_DATE: 'INSERT_OR_OVERWRITE_BY_DATE',
    REPLACE_ALL: 'REPLACE_ALL',
    APPEND: 'APPEND',
    INSERT_OR_UPDATE_BY_PRIMARY_KEY: 'INSERT_OR_UPDATE_BY_PRIMARY_KEY',
    INSERT_OR_OVERWRITE_BY_COLUMNS: 'INSERT_OR_OVERWRITE_BY_COLUMNS'
} as const;

export const TRANSFORMATION_INGEST_MODE = {
    INSERT_OR_OVERWRITE_BY_PARTITION: 'INSERT_OR_OVERWRITE_BY_PARTITION',
    REPLACE_ALL: 'REPLACE_ALL',
    APPEND: 'APPEND',
    INSERT_OR_UPDATE_BY_PRIMARY_KEY: 'INSERT_OR_UPDATE_BY_PRIMARY_KEY',
    INSERT_OR_OVERWRITE_BY_COLUMNS: 'INSERT_OR_OVERWRITE_BY_COLUMNS'
} as const;

export const HTTP_CODE = {
    OK: 200,
    MULTIPLE_CHOICES: 300,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500
};

export const CRON_FREQUENCY_SWITCH_OPTIONS = [
    {
        name: intl.formatMessage({ id: 'common.daily' }),
        value: SCHEDULE_FREQUENCY.DAILY
    },
    {
        name: intl.formatMessage({ id: 'common.weekly' }),
        value: SCHEDULE_FREQUENCY.WEEKLY
    },
    {
        name: intl.formatMessage({ id: 'common.monthly' }),
        value: SCHEDULE_FREQUENCY.MONTHLY
    },
    {
        name: intl.formatMessage({ id: 'common.custom' }),
        value: SCHEDULE_FREQUENCY.CUSTOM
    }
];

export const PROMISE_STATUS = {
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected'
};

export const SECRET_VALUE_PLACEHOLDER = 'secret_12345';

export const TARGET_TYPE = {
    BIGQUERY: 'BIGQUERY',
    SNOWFLAKE: 'SNOWFLAKE'
} as const;

export const TARGET_AUTH_METHODS_MAP = {
    [TARGET_TYPE.BIGQUERY]: {
        SERVICE_ACCOUNT: 'SERVICE_ACCOUNT'
    },
    [TARGET_TYPE.SNOWFLAKE]: {
        KEY_PAIR: 'KEY_PAIR'
    }
};

export const TEMP_ID_PREFIX = 'temp_id_';

export const SERVICE = {
    INGESTION: 'INGESTION'
} as const;

export const EVENT_TYPE_GROUP = {
    SOURCE: 'SOURCE',
    FEED: 'FEED'
} as const;

export const EVENT_TYPE = {
    SOURCE_EDITED: 'SOURCE_EDITED',
    FEED_RUN_FAILED: 'FEED_RUN_FAILED'
};

export const EVENT_TYPES_MAP = {
    [EVENT_TYPE_GROUP.SOURCE]: [ EVENT_TYPE.SOURCE_EDITED ],
    [EVENT_TYPE_GROUP.FEED]: [ EVENT_TYPE.FEED_RUN_FAILED ]
};

export const ADVERITY_OPTIONS_FIELD_TYPE = {
    BOOLEAN: 'boolean',
    STRING: 'string',
    CHOICE: 'choice',
    URL: 'url',
    FIELD: 'field',
    BASE64: 'base64',
    INTEGER: 'integer',
    DATETIME: 'datetime',
    EMAIL: 'email'
};
